import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"
import { Company } from "./Company"
import { FirestoreClass } from "./FirestoreClass"

export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    floor: string = ""
    room: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string
            this.number = data.number as string
            this.postcode = data.postcode as string
            this.location = data.location as string
            this.floor = data.floor as string
            this.room = data.room as string
        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            floor: this.floor,
            room: this.room
        }
    }
}
export class Interested extends FirestoreClass {
    private _company: Company
    gender: string = ""
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string = ""
    note: string = ""
    dispatcher: string = ""

    get fullName() {
        return `${this.firstName} ${this.lastName}`.trim()
    }

    constructor(company: Company, id: string = "") {
        super(id)
        this._company = company
    }

    override fromData(data: Record<string, unknown>): void {
        if (data.gender) {
            this.gender = data.gender as string
        }
        if (data.firstName) {
            this.firstName = data.firstName as string
        }
        if (data.lastName) {
            this.lastName = data.lastName as string
        }
        if (data.email) {
            this.email = data.email as string
        }
        if (data.phone) {
            this.phone = data.phone as string
        }
        if (data.note) {
            this.note = data.note as string
        }
        if (data.dispatcher) {
            this.dispatcher = data.dispatcher as string
        }
    }
    override toData() {
        const data: Record<string, Partial<unknown>> = {}
        data.gender = this.gender
        data.firstName = this.firstName
        data.lastName = this.lastName
        data.email = this.email
        data.phone = this.phone
        data.note = this.note
        if (this.dispatcher) {
            data.dispatcher = this.dispatcher
        }
        data._searchIndex = [
            this.firstName.toUpperCase(),
            this.lastName.toUpperCase(),
            this.email.toUpperCase(),
            this.phone.toUpperCase()
        ]
        return data
    }
    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(this._company.ref, "interested")
    }
}