import { Company } from "@/classes/Company"
import { firestore } from "@/firebase"
import { collection, onSnapshot, query, Unsubscribe, where } from "firebase/firestore"
import { Module } from "vuex"

export interface PortalCustomerState {
    customers: Company[]
    isLoading: boolean
    firebaseListener: {
        customers: Unsubscribe | null
    }
}

const module: Module<PortalCustomerState, unknown> = {
    state: {
        isLoading: true,
        customers: [],
        firebaseListener: {
            customers: null
        }
    },
    actions: {
        attachPortalCustomers({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firebaseListener.customers) {
                    state.isLoading = false
                    resolve(state.customers)
                    return
                }
                state.isLoading = true
                const ref = query(collection(firestore, "company"), where("type", "!=", "dispatcher"))
                state.firebaseListener.customers = onSnapshot(ref, (snapshot) => {
                    state.customers = []
                    for (const doc of snapshot.docs) {
                        const company = new Company(doc.id)
                        company.fromData(doc.data())
                        state.customers.push(company)
                    }
                    state.isLoading = false
                    resolve(state.customers)
                }, (err) => {
                    state.isLoading = false
                    reject(err)
                })
            })
        }
    }
}

export default module