import { Customer } from "@/classes/Customer"
import { Employee } from "@/classes/Employee"
import { auth } from "@/firebase"
import router from "@/router"
import { collection, query, where, getDocs, Unsubscribe, onSnapshot } from "firebase/firestore"
import { Module } from "vuex"
import store from ".."

export interface CustomerState {
    isLoading: boolean
    customers: Customer[]
    firestoreListener: {
        customers: Unsubscribe | null
    }
}

const module: Module<CustomerState, unknown> = {
    state: {
        isLoading: false,
        customers: [],
        firestoreListener: {
            customers: null
        }
    },
    actions: {
        getCustomer({ state }, id: string) {
            return new Promise(async (resolve, reject) => {
                for (const customer of state.customers) {
                    if (customer.id == id) {
                        resolve(customer)
                        return
                    }
                }
                const company = store.state.company!.company!
                const _i = new Customer(company, "", id)
                if (await _i.load()) {
                    state.customers.push(_i)
                    resolve(_i)
                    return
                }
                reject(new Error("not_found"))
            })
        },
        attachCustomers({ state }) {
            return new Promise(async (resolve, reject) => {
                if (state.firestoreListener.customers) {
                    state.isLoading = false
                    resolve(state.customers)
                    return
                }
                state.isLoading = true
                const col = collection(store.state.company!.company!.ref, "customer")
                const sub : string = store.state.auth?.profile?.sub??""

                const employee = new Employee(store.state.company!.company!, sub)
                if(await employee.load()){
                    const subsIWorkFor = [employee.id].concat(employee.assistantOf).concat(employee.representativeOf)
                    const ref = query(col, where("owner", "in", subsIWorkFor))
                   
                    state.firestoreListener.customers = onSnapshot(ref, (snapshot) => {
                            
                        state.customers = []
                        for (const doc of snapshot.docs) {
                            const obj = new Customer(store.state.company!.company!, "", doc.id)
                            obj.fromData(doc.data())
                            state.customers.push(obj)
                        }
                        state.isLoading = false
                        resolve(state.customers)
                    }, (err) => {
                        if(err.message.trim().startsWith("false for 'list'")) {
                            auth.signOut()
                            router.push("/login")
                        }
                        reject(err)
                    })

                    
                }
            })
        },
        searchCustomer({ state }, search: string) {
            return new Promise(async (resolve, reject) => {
                if (!search) {
                    reject(new Error("no_search_term"))
                    return
                }
                const sub : string = store.state.auth?.profile?.sub??""

                const company = store.state.company!.company!
                const searchTerms = search.toUpperCase().split(" ")
                const collectionRef = collection(company.ref, "customer")
                const queryRef = query(
                    collectionRef,
                    where("owner", "==", sub),
                    where("dispatcher", "==", store.state.auth!.profile!.company),
                    where("_searchIndex", "array-contains-any", searchTerms)
                )
                const searchResults = await getDocs(queryRef)
                const customer: Customer[] = []
                for (const result of searchResults.docs) {
                    const _i = new Customer(company, "", result.id)
                    _i.fromData(result.data())
                    const customerIndex = state.customers.findIndex((a) => a.id == _i.id)
                    if (customerIndex == -1) {
                        state.customers.push(_i)
                    }
                    customer.push(_i)
                }
                resolve(customer)
            })
        }
    }
}
export default module