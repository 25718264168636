import { firestore } from "@/firebase"
import { collection, doc } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"
export interface CompanyUser {
    admin?: boolean
}
export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    room: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string
            this.number = data.number as string
            this.postcode = data.postcode as string
            this.location = data.location as string
            this.room = data.room as string
        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            room: this.room
        }
    }
}
export class Company extends FirestoreClass {
    name: string = ""
    type: string = ""
    activeEmployees: string[] = []
    inactiveEmployees: string[] = []

    constructor(id: string = "") {
        super(id)
    }

    override fromData(data: Record<string, unknown>) {
        this.name = data.name as string
        this.type = data.type as string
        this.activeEmployees = data.activeEmployees as string[]
        this.inactiveEmployees = data.inactiveEmployees as string[]
    }
    override toData() {
        const data: Record<string, Partial<unknown>> = {
            name: this.name,
            type: this.type,
            activeEmployees: this.activeEmployees,
            inactiveEmployees: this.inactiveEmployees

        }
        return data
    }

    override get ref() {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef() {
        return collection(firestore, "company")
    }
}