import { collection, onSnapshot, Unsubscribe } from "firebase/firestore"
import store from ".."

import { Module } from "vuex"
import { Employee } from "@/classes/Employee"
import { Company } from "@/classes/Company"

export interface EmployeeState {
    employees: Employee[] | null,
    firestoreListener: {
        employees: Unsubscribe | null
    }
}

const module: Module<EmployeeState, unknown> = {
    state: {
        employees: null,
        firestoreListener: {
            employees: null
        }
    },
    actions: {
        attachEmployees({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firestoreListener.employees) {
                    resolve(state.employees)
                    return
                }
                const col = collection(store.state.company!.company!.ref, "employee")
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const sub: string = store.state.auth?.profile?.sub??""
                //const ref = query(col, where("active", "==", true))
        
                state.firestoreListener.employees = onSnapshot(col, (snapshot) => {
                    state.employees = []
                    for (const doc of snapshot.docs) {
                        const obj = new Employee(store.state.company!.company!, doc.id)
                        obj.fromData(doc.data())
                        state.employees.push(obj)
                    }
                    resolve(state.employees)
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        },
        getCompanyEmployees({ state }, company: Company) {
            return new Promise((resolve, reject) => {
                const col = collection(company.ref, "employee")
        
                const employees: Employee[] = []
                state.firestoreListener.employees = onSnapshot(col, (snapshot) => {
                    for (const doc of snapshot.docs) {
                        const obj = new Employee(company, doc.id)
                        obj.fromData(doc.data())
                        employees.push(obj)
                    }
                    resolve(employees)
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }
        
    }
}
export default module