import { createStore } from "vuex"
import auth, { AuthState } from "./modules/auth"
import app, { AppState } from "./modules/app"
import object, { ObjectState } from "./modules/object"
import company, { CompanyState } from "./modules/company"
import interested, { InterestedState } from "./modules/interested"
import financer, { FinancerStore } from "./modules/financer"
import portalCustomer, { PortalCustomerState } from "./modules/portalCustomer"
import customer, { CustomerState } from "./modules/customer"
import incommingCall, { IncommingCallState } from "./modules/incommingCall"
import publicUser, { PublicUserState } from "./modules/publicUser"
import user, { UserState } from "./modules/user"
import employees, { EmployeeState } from "./modules/employee"


export interface StoreState {
    auth?: AuthState
    app?: AppState
    object?: ObjectState
    company?: CompanyState
    interested?: InterestedState
    financer?: FinancerStore
    portalCustomer?: PortalCustomerState
    customer?: CustomerState
    incommingCall?: IncommingCallState
    publicUser?: PublicUserState
    user?: UserState
    employees?: EmployeeState
    isLoading: boolean
}

export default createStore<StoreState>({
    state: {
        isLoading: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        app,
        object,
        company,
        interested,
        financer,
        portalCustomer,
        customer,
        incommingCall,
        publicUser,
        user,
        employees
    }
})