import { createApp } from "vue"
import app from "./app.vue"
import router from "./router"
import store from "./store"
import "./index.css"

import "@/firebase"
import { ObserveVisibility } from "vue-observe-visibility"

createApp(app)
    .use(store)
    .use(router)
    .directive("observe-visibility", {
        beforeMount: (el, binding, vnode) => {
            (vnode as any).context = binding.instance
            ObserveVisibility.bind(el, binding, vnode)
        },
        updated: ObserveVisibility.update,
        unmounted: ObserveVisibility.unbind
    })
    .mount("#app")

