import { Company } from "@/classes/Company"
import { PublicUser } from "@/classes/PublicUser"
import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import store from ".."

export interface CompanyState {
    isLoading: boolean
    company: Company | null
    employees: PublicUser[]
    firebaseListener: {
        company: Unsubscribe | null
    }
}

const module: Module<CompanyState, unknown> = {
    state: {
        isLoading: true,
        company: null,
        employees: [],
        firebaseListener: {
            company: null
        }
    },
    actions: {
        attachCompany({ state }) {
            return new Promise(async (resolve, reject) => {
                if (state.firebaseListener.company) {
                    state.isLoading = false
                    resolve(state.company)
                    return
                }
                state.isLoading = true
                const _c = new Company(store.state.auth!.profile!.company!)
                state.firebaseListener.company = onSnapshot(_c.ref, async (snapshot) => {
                    if (snapshot.exists()) {
                        state.employees = []
                        const company = new Company(snapshot.id)
                        company.fromData(snapshot.data())
                        state.company = company
                        for (const userID of company.activeEmployees) {
                            const publicUser: PublicUser = await store.dispatch("getUserInfo", userID)
                            state.employees.push(publicUser)
                        }
                        state.isLoading = false
                        resolve(state.company)
                    } else {
                        reject("no_company")
                    }
                }, (err) => {
                    state.isLoading = false
                    reject(err)
                })
            })
        }
    }
}
export default module