import { FirestoreClass } from "./FirestoreClass"
import { Employee } from "./Employee"
import { collection, doc } from "firebase/firestore"

export enum Permission {
    GlobalAdmin = "GlobalAdmin",
    CompanyAdmin = "CompanyAdmin",
    BetaTester = "BetaTester",
    Europace = "Europace"
}

export class EmployeePermission extends FirestoreClass {
    private _employee: Employee
    permissions: Permission[] = []

    constructor(employee: Employee) {
        super("Permission")
        this._employee = employee
    }

    fromData(data: Record<string, unknown>): void {
        if (data) {
            this.permissions = data.permissions as Permission[] || []
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            permissions: this.permissions
        }

        return data
    }

    get employee() {
        return this._employee
    }

    override get ref() {
        return doc(this.collectionRef, this._id)
    }
    override get collectionRef() {
        return collection(this.employee.ref, "meta")
    }
}
