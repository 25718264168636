
import { IncommingCall } from "@/classes/IncommingCall"
import { defineComponent, onMounted, ref } from "vue"
import { useStore } from "vuex"
import IconItem from "../theme/icon-item.vue"
import { mdiClose, mdiPhoneIncoming } from "@mdi/js"
import { Customer } from "@/classes/Customer"

export default defineComponent({
    props: {
        call: {
            type: IncommingCall,
            default: new IncommingCall()
        }
    },
    setup(props) {
        const store = useStore()
        const customer = ref<Customer>()

        const setCallDone = async () => {
            console.log(props.call)
            let call: IncommingCall = new IncommingCall(props.call.id)
            await call.load()
            call.done = true
            call.save()
        }

        const gotoCustomer = () => {
            if (customer.value) {
                let route = `/customer/${customer.value.id}`
                window.open(route, "_blank")
            }
        }

        onMounted(async () => {
            if (store.state.customer.customers.length == 0) {
                await store.dispatch("attachCustomers")
            }

            if (store.state.customer.customers.length > 0) {
                for (let _customer of store.state.customer.customers as Customer[]) {
                    if (_customer.phone == props.call.incommingNumber) {
                        customer.value = _customer
                        if(_customer.owner == store.state.auth.profile.sub){
                            return
                        }
                    }
                }
            }

        })

        return {
            customer,
            mdiClose,
            mdiPhoneIncoming,
            setCallDone,
            gotoCustomer
        }
    },
    components: { IconItem }
})
