import { Company } from "@/classes/Company"
import { PublicUser } from "@/classes/PublicUser"
import { firestore } from "@/firebase"
import { collection, onSnapshot, Unsubscribe, query, where } from "firebase/firestore"
import { Module } from "vuex"
import store from ".."

export interface FinancerStore {
    isLoadingCompanies: boolean
    companies: Company[]
    firebaseListener: {
        companies: Unsubscribe | null
    }
}

const module: Module<FinancerStore, unknown> = {
    state: {
        isLoadingCompanies: true,
        companies: [],
        firebaseListener: {
            companies: null
        }
    },
    actions: {
        getFinancersForCompany({}, company: Company) {
            return new Promise(async (resolve) => {
                const financiers: PublicUser[] = []
                for (const userID of company.activeEmployees) {
                    const publicUser: PublicUser = await store.dispatch("getUserInfo", userID)
                    if (publicUser) {
                        financiers.push(publicUser)
                    }
                }
                resolve(financiers)
            })
        },
        attachFinancerCompanies({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firebaseListener.companies) {
                    state.isLoadingCompanies = false
                    resolve(state.companies)
                    return
                }
                state.isLoadingCompanies = true
                const ref = collection(firestore, "company")
                const firestoreQuery = query(ref, where("type", "==", "financer"))
                state.firebaseListener.companies = onSnapshot(firestoreQuery, (snapshot) => {
                    state.companies = []
                    for (const doc of snapshot.docs) {
                        const company = new Company(doc.id)
                        company.fromData(doc.data())
                        state.companies.push(company)
                    }
                    state.isLoadingCompanies = false
                    resolve(state.companies)
                }, (err) => {
                    state.isLoadingCompanies = false
                    reject(err)
                })
            })
        }
    }
}
export default module