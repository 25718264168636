import { firestore } from "@/firebase"
import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"

import { FirestoreClass } from "./FirestoreClass"

export type WorkTime = ({ start: { hour: number, minute: number }, end: { hour: number, minute: number } } | null)
export class User extends FirestoreClass {
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    picture: string | null = null
    useTK: boolean = false
    internalnumber:string = ""
    externalnumber:string = ""
    replacenamesTK: boolean = false

    get fullName() {
        return `${this.firstName} ${this.lastName}`.trim()
    }

    constructor(id: string) {
        super(id)
    }

    override fromData(data: Record<string, unknown>): void {
        this.firstName = data.firstName as string
        this.lastName = data.lastName as string
        this.email = data.email as string
        this.useTK = data.useTK as boolean
        this.internalnumber = data.internalnumber as string
        this.externalnumber = data.externalnumber as string
        this.replacenamesTK = data.replacenamesTK as boolean

        if (data.picture) {
            this.picture = data.picture as string
        }
    }

    toData(){
        const data: Record<string, unknown> = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            picture: this.picture,
            useTK: this.useTK,
            internalnumber: this.internalnumber,
            externalnumber: this.externalnumber,
            replacenamesTK: this.replacenamesTK
        }

        return data
    }
    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(firestore, "user")
    }
}